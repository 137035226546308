const STATMODIFIERS = {
  1: { value: -5 },
  2: { value: -4 },
  3: { value: -4 },
  4: { value: -3 },
  5: { value: -3 },
  6: { value: -2 },
  7: { value: -2 },
  8: { value: -1 },
  9: { value: -1 },
  10: { value: 0 },
  11: { value: 0 },
  12: { value: 1 },
  13: { value: 1 },
  14: { value: 2 },
  15: { value: 2 },
  16: { value: 3 },
  17: { value: 3 },
  18: { value: 4 },
  19: { value: 4 },
  20: { value: 5 },
  21: { value: 5 },
  22: { value: 6 },
  23: { value: 6 },
  24: { value: 7 },
  25: { value: 7 },
  26: { value: 8 },
  27: { value: 8 },
  28: { value: 9 },
  29: { value: 9 },
  230: { value: 10 },
}

export default STATMODIFIERS;